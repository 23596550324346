



































































































import { Component, Vue } from 'vue-property-decorator';
import InvestmentStrategyInitialBalanceViewModel from
  '@/vue-app/view-models/allianz-dashboard/access-more-strategies/investment-strategy-initial-balance-view-model';

@Component({ name: 'InvestmentStrategyInitialBalance' })
export default class InvestmentStrategyInitialBalance extends Vue {
  view_model = Vue.observable(
    new InvestmentStrategyInitialBalanceViewModel(this),
  );

  created() {
    this.view_model.initialize();
  }
}
